import React, { useState, useEffect } from 'react';
import { Table, Button, Card, Space, message, Tabs, Tag, Upload } from 'antd';
import config from '../../../../config/config';
import dayjs from 'dayjs';
import { getWarehouseHistories } from '../../../../api/warehouseHistoryApi';
import TabPane from 'antd/es/tabs/TabPane';
import warehouseEntryManagementApi from '../../../../api/warehouseEntryManagementApi';
import { numberFormat } from '../../../../utils/numberFormat';
import { DownloadOutlined, UploadOutlined } from '@ant-design/icons';
import axiosInstance from '../../../../api/axiosInstance';

const SparepartWarehouseTable = ({ filters }) => {
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: config.pageSize,
    total: 0,
  });
  const [paginationEntry, setPaginationEntry] = useState({
    page: 1,
    pageSize: config.pageSize,
    total: 0,
  });

  const [data, setData] = useState([]);
  const [dataEntry, setDataEntry] = useState([]);

  useEffect(() => {
    const paginate = { ...pagination, page: 1 };
    setPagination(paginate)
    fetchSparepart({ ...filters, ...paginate });
  }, [filters]);

  const fetchData = async (filters) => {
    try {
      setLoading(true);
      filters.model = 'pallet';
      filters.material_category = 'CCDC'; // Kho tieu hao
      if (filters?.start_date) filters.start_date = dayjs(filters.start_date).format('YYYY-MM-DD');
      if (filters?.end_date) filters.end_date = dayjs(filters.end_date).format('YYYY-MM-DD');
      
      const response = await getWarehouseHistories(filters); // Thay đổi endpoint theo API của bạn
      setData(response.data.data ?? []);
      response.data?.pagination && setPagination(response.data.pagination)
      setLoading(false);
    } catch (error) {
      message.error(error.message);
    }
  };

  const fetchWarehouseInventory = async (filters) => {
    try {
      filters.model = 'pallet';
      filters.material_category = 'CCDC'; // Kho tieu hao
      const response = await warehouseEntryManagementApi.getWarehouseEntriesInventory(filters); // Thay đổi endpoint theo API của bạn
      setDataEntry(response.data.data ?? []);
      response.data?.pagination && setPaginationEntry(response.data.pagination)
    } catch (error) {
      message.error(error.message);
    }
  };

  const fetchSparepart = async (filters) => {
    try {
      // filters.material_category_id = 'CCDC'; // Kho tieu hao
      filters.type = 3; // Kho tieu hao
      filters.withs = 'attributes';
      const response = await axiosInstance.get(config.apiUrl + '/materials', { params: filters });
      setDataEntry(response.data.data ?? []);
      response.data?.pagination && setPaginationEntry(response.data.pagination)
    } catch (error) {
      message.error(error.message);
    }
  };

  const columns = [
    { title: 'STT', dataIndex: 'stt', align: 'center', width: '50px', render: (_, record, index) => ((pagination.page - 1) * pagination.pageSize) + index + 1 },
    { title: 'Loại', dataIndex: 'type', align: 'center', width: '80px', render: (value) => value === 'export' ? <Tag color="#2DB7F5">XUẤT</Tag> : <Tag color="#F50">NHẬP</Tag> },
    { title: 'Vị trí', dataIndex: 'warehouse_location_id', align: 'center', width: '80px' },
    { title: 'Mã Pallet', dataIndex: 'warehousehistoryable_id', align: 'center', width: '120px' },
    { title: 'Tên vật tư tiêu hao', dataIndex: 'material_name', align: 'center', width: '150px', render: (_, record) => record?.pallet?.material?.material_name },
    { title: 'Số lượng', dataIndex: 'quantity', align: 'center', width: '100px', render: (value) => numberFormat(value) },
    { title: 'Tồn', dataIndex: 'inventory_quantity', align: 'center', width: '100px', render: (value) => numberFormat(value) },
    { title: 'Đơn vị tính', dataIndex: 'unit_name', align: 'center', width: '100px', render: (_, record) => record?.unit?.unit_name },
    { title: 'Thời gian', dataIndex: 'created_at', align: 'center', width: '100px' },
    { title: 'Người tạo', dataIndex: 'created_by', align: 'center', width: '100px', render: (_, record) => record?.created_by?.name }
  ];

  const columnsEntry = [
    { title: 'STT', dataIndex: 'stt', align: 'left', width: '50px', render: (_, record, index) => ((paginationEntry.page - 1) * paginationEntry.pageSize) + index + 1 },
    // { title: 'Vị trí', dataIndex: 'warehouse_location_id', align: 'left', width: '80px' },
    { title: 'Mã vật tư', dataIndex: 'id', align: 'left', width: '100px' },
    { title: 'Tên vật tư tiêu hao', dataIndex: 'material_name', align: 'left', width: '150px' },
    { title: 'Đơn vị tính', dataIndex: 'unit_name', align: 'left', width: '100px', render: (_, record) => record?.unit?.unit_name },
    { title: 'Số lượng', dataIndex: 'quantity', align: 'left', width: '100px', render: (_, record) => {
      return numberFormat(record?.attributes?.filter(({material_attribute_id}) => material_attribute_id === 'sparepart_quantity')?.reduce((a, b) => (a + Number(b?.value)), 0))
    } },
    { title: 'Đơn giá', dataIndex: 'price', align: 'left', width: '100px', render: (_, record) => {
      return numberFormat(record?.attributes?.filter(({material_attribute_id}) => material_attribute_id === 'sparepart_price')?.reduce((a, b) => (a + Number(b?.value)), 0))
    } },
    { title: 'Thành tiền', dataIndex: 'sum_money', align: 'left', width: '100px', render: (_, record) => {
      return numberFormat(record?.attributes?.filter(({material_attribute_id}) => material_attribute_id === 'sparepart_sum_money')?.reduce((a, b) => (a + Number(b?.value)), 0))
    } },
  ];

  const handleUploadChange = (info) => {
    setLoading(true);
    try {
      if (info.file.status === 'uploading') {
        setLoading(true);
      } else {
        if (info.file.status === 'done') {
          if (!info.file?.response?.success)
            throw new Error(info.file?.response?.message ?? `${info.file.name} - Thực hiện thất bại`);
          fetchSparepart({ ...filters, ...pagination });
          message.success(`${info.file.name} - Upload thành công`);
        } else if (info.file.status === 'error') {
          message.error(info.file?.response?.message);
        }
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const uploadProps = {
    name: 'file',
    accept: '.xlsx, .xls',
    action: config.apiUrl + '/import-sparepart',
    onChange: handleUploadChange,
    showUploadList: false,
    loading: loading,
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`
    }
  };

  return (
    // <Tabs defaultActiveKey="1" type="card" style={{ width: '100%' }} className='custom-oi-tabs' animated={false} moreIcon={null}>
    //   <TabPane tab="Lịch sử nhập/xuất kho tiêu hao" key="1">
    //     <Table
    //       className="table-mh70"
    //       loading={loading}
    //       columns={columns}
    //       dataSource={data.map((e, key) => ({ ...e, key }))}
    //       bordered
    //       pagination={{
    //         current: pagination.page,
    //         pageSize: pagination.pageSize,
    //         total: pagination.total,
    //         onChange: (page, pageSize) => {
    //           setPagination({ ...pagination, page, pageSize });
    //           fetchData({ ...filters, page, pageSize });
    //         }
    //       }}
    //       scroll={{ x: '880px' }}
    //       size="small"
    //     />
    //   </TabPane>
    //   <TabPane tab="Tồn kho tiêu hao" key="2">
    //     <Table
    //       className="table-mh70"
    //       loading={loading}
    //       columns={columnsEntry}
    //       dataSource={dataEntry.map((e, key) => ({ ...e, key }))}
    //       bordered
    //       pagination={{
    //         ...paginationEntry,
    //         onChange: (page, pageSize) => {
    //           setPagination({ ...paginationEntry, page, pageSize });
    //           fetchWarehouseInventory({ page, pageSize });
    //         },
    //       }}
    //       scroll={{ x: '500px' }}
    //       size="small"
    //     />
    //   </TabPane>
    // </Tabs>
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '5px' }}>
        <Space>
          <Button target="_blank" href={config.apiUrl.replace('/api', '') + '/excel/VatTuTieuHao.xlsx'} icon={<DownloadOutlined />}>Tải file mẫu</Button>
          <Upload {...uploadProps}>
            <Button type='primary' icon={<UploadOutlined />} >Upload vật tư, công cụ</Button>
          </Upload>
        </Space>
      </div>
      <Table
        className="table-mh70"
        loading={loading}
        columns={columnsEntry}
        dataSource={dataEntry.map((e, key) => ({ ...e, key }))}
        bordered
        pagination={{
          ...paginationEntry,
          onChange: (page, pageSize) => {
            setPagination({ ...paginationEntry, page, pageSize });
            fetchSparepart({ page, pageSize });
          },
        }}
        scroll={{ x: '500px' }}
        size="small"
      />
    </div>
  );
};

export default SparepartWarehouseTable;
